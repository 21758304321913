import gql from 'graphql-tag'

/**
 * 问题目录
 */
export const helpCenterMenuTree = gql`
  query helpCenterMenuTree($filter: FilterHelpCenterCommonInput!) {
    helpCenterMenuTree(filter: $filter) {
      menuId
      menuName
      parentId
      icon
      subList {
        menuId
        menuName
        parentId
        icon
        subList {
          menuId
          menuName
          parentId
          icon
          subList {
            menuId
            menuName
            parentId
            icon
          }
        }
      }
    }
  }
`
/**
 * 查询三级菜单下的问题列表
 */
export const helpCenterQuestionList = gql`
  query helpCenterQuestionList(
    $filter: FilterHelpCenterQuestionListBatchInput!
  ) {
    helpCenterQuestionList(filter: $filter) {
      questionId
      question
      answer
      parentId
    }
  }
`
/**
 * 查询三级菜单下的问题详情
 */
export const helpCenterQuestionDetail = gql`
  query helpCenterQuestionDetail(
    $filter: FilterHelpCenterQuestionCommonInput!
  ) {
    helpCenterQuestionDetail(filter: $filter) {
      questionId
      question
      answer
      parentId
    }
  }
`

/**
 * 关键字搜索问题分页查询
 */
export const helpCenterListByKeyword = gql`
  query helpCenterListByKeyword($filter: FilterHelpCenterListByKeywordInput!) {
    helpCenterListByKeyword(filter: $filter) {
      totalPages
      totalRows
      list {
        questionId
        question
        answer
        parentId
      }
    }
  }
`
/**
 * 常见问题分页查询
 */
export const helpCenterListFaq = gql`
  query helpCenterListFaq($filter: FilterHelpCenterPaginationInput!) {
    helpCenterListFaq(filter: $filter) {
      totalPages
      totalRows
      list {
        questionId
        question
        answer
        parentId
      }
    }
  }
`
/**
 * 查询父级菜单下的常见问题列表
 */
export const helpCenterListMenuFaq = gql`
  query helpCenterListMenuFaq($filter: FilterHelpCenterMenuFaqInput!) {
    helpCenterListMenuFaq(filter: $filter) {
      questionId
      question
      answer
      parentId
      path
    }
  }
`
